<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true"></nav-form>
    <section>
      <article style="height: 173px; width:411px">
        <field
          name="codigo"
          widget="input"
          searchable
          label="Código"
          placeholder="Cód."
          width="67px"
          style="top:9px; left:25px;"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="descripcion"
          widget="input"
          searchable
          label="Nombre"
          help="Descripcion"
          placeholder="Descripcion"
          width="270px"
          style="top:9px; left:95px;"
        />
        <field
          name="vencimientos"
          widget="input"
          searchable
          label="Vencimientos"
          placeholder="0.00"
          width="67px"
          style="top:49px; left:25px;"
        />

        <field
          name="periodicidad"
          widget="input"
          searchable
          label="Periodicidad"
          placeholder="0"
          width="67px"
          style="top:49px; left:95px;"
          inputStyle="text-align: right;"
        />
        <field
          name="periodicidad_unidades"
          widget="select"
          :options="{'día(s)':'days', 'mes(es)':'months', 'semana(s)':'weeks'}"
          placeholder="days"
          width="85px"
          style="top:64px; left:165px;"
          default="days"
        />
        <field
          name="tipo"
          widget="select"
          :options="{'Letra':'letra','Cheque/Pagaré':'cheque','Trans. Bancaria':'transferencia','Efectivo':'efectivo'}"
          placeholder="days"
          width="120px"
          label="Tipo"
          style="top:89px; left:25px;"
          default="days"
        />
        <field
          name="penalizacion"
          widget="input"
          searchable
          label="Penalizacion"
          placeholder="0.00%"
          width="67px"
          style="top:89px; left:175px;"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{count}}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    return {
      title: "Forma de Pago",
      dbAdapter: "forma_pago",
      primary: "codigo",
      sequence: { name: "forma_pago" }
    };
  }
};
</script>
